import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css'
// 按需引入echarts
import * as echarts from 'echarts/core';
import countTo from "vue-count-to";
Vue.component('countTo', countTo)
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  VisualMapComponent,
  GeoComponent,
  DataZoomComponent,
} from 'echarts/components';
import {
  LineChart,
  BarChart,
  MapChart,
  PieChart,
  PictorialBarChart,
} from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts-gl'

echarts.use([
  DataZoomComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  UniversalTransition,
  TitleComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  PieChart,
  PictorialBarChart
]);

import * as utils from "@/utils";
Vue.prototype.$echarts = echarts
Vue.prototype.$request = utils.request
Vue.prototype.$utils = utils
// 按需引入echarts
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default class DateCounter{
  constructor(){
    // 当前时间
    this.nowTime = null
    // 计时器
    this.timer = null
    this.beginTimer()
  }
  beginTimer(){
    this.timer = setInterval(() => {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      this.nowTime = {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
      }
    }, 1000)
  }
  getCurDate(){
    if(!this.nowTime) return ''
    return `${this.nowTime.year} ${this.nowTime.month} ${this.nowTime.day}`
  }

  getCurTime(){
    if(!this.nowTime) return ''
    return `${this.nowTime.hours}:${this.nowTime.minutes}:${this.nowTime.seconds}`
  }
  destroyTimer(){
    window.clearInterval(this.timer)
  }
}



<template>
  <div id="app">
    <ScreenFit>
      <router-view/>
    </ScreenFit>
  </div>
</template>
<script>
  import ScreenFit from "@/components/ScreenFit";
  export default {
    components:{
      ScreenFit
    }
  }
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'pangmenzhengdao';
  src:  url('./assets/fonts/pangmenzhengdao.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

</style>

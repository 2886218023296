import axios from 'axios'


axios.defaults.baseURL = '/api'

// 创建 axios 实例
const requests = axios.create({
  timeout: 6000 // 请求超时时间
})
 
// 错误处理函数
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      console.error(data.message||data.msg)
    }
    if (error.response.status === 401) {
      console.error('你没有权限。')
    }
  }
  return Promise.reject(error)
}

// request interceptor(请求拦截器)
requests.interceptors.request.use(config => {
  return config
}, err)

// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
  const res = response.data
  if (res.code !== 0&&res.code!==200) { 
    console.error(res.message||res.msg)
    // 401:未登录;
    if (res.code === 401||res.code === 403||res.code===999) {
      console.error('未登录')
    }
    return Promise.reject('error')
  } else {
    return res
  }
}, err)

export default requests
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/integratedPlatform'
  },
  {
    // 综合平台
    path: '/integratedPlatform',
    name: 'integratedPlatform',
    meta: {
      title: '河南智慧林业综合平台'
    },
    component: () => import(/* webpackChunkName: "integratedPlatform" */ '../views/integratedPlatform/index.vue')
  },
  {
    // 调查监控
    path: '/investigationMonitor',
    name: 'investigationMonitor',
    meta: {
      title: '森林资源监测'
    },
    component: () => import(/* webpackChunkName: "investigationMonitor" */ '../views/investigationMonitor/index.vue')
  },
  {
    // 森林防火
    path: '/forestFirePrevention',
    name: 'forestFirePrevention',
    meta: {
      title: '森林防火'
    },
    component: () => import(/* webpackChunkName: "forestFirePrevention" */ '../views/forestFirePrevention')
  },
  {
    // 古树名树
    path: '/ancientBrightTrees',
    name: 'ancientBrightTrees',
    meta: {
      title: '古树名树'
    },
    component: () => import(/* webpackChunkName: "ancientBrightTrees" */ '../views/ancientBrightTrees')
  },
  {
    // 林长制
    path: '/linzhangzhi',
    name: 'linzhangzhi',
    meta: {
      title: '林长制'
    },
    component: () => import(/* webpackChunkName: "linzhangzhi" */ '../views/linzhangzhi')
  },
  {
    // 自然保护地
    path: '/ziranbaohudi',
    name: 'ziranbaohudi',
    meta: {
      title: '自然保护地'
    },
    component: () => import(/* webpackChunkName: "ziranbaohudi" */ '../views/ziranbaohudi')
  },
  {
    // 森林资源检测
    path: '/senlinziyuanjiance',
    name: 'senlinziyuanjiance',
    meta: {
      title: '森林资源检测'
    },
    component: () => import(/* webpackChunkName: "senlinziyuanjiance" */ '../views/senlinziyuanjiance')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
